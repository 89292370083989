/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const searchDocumentState = reactive({
	columnsList: [
		{
			label: 'Customer',
			field: 'customer',
			width: '108px',
			tdClass: 'center-header',
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '108px',
			tdClass: 'center-header',
		},
		{
			label: 'Dispatch Number',
			field: 'dispatch_number',
			width: '108px',
			tdClass: 'center-header',
		},
		{
			label: 'Invoice Number',
			field: 'invoice_number',
			width: '108px',
			tdClass: 'center-header',
		},
		{
			label: 'Reminder Number',
			field: 'reminder_number',
			width: '108px',
			tdClass: 'center-header',
		},
	],
})

export default function storeSearchDocumentState() {
	return {
		...toRefs(searchDocumentState),
	}
}
