import { render, staticRenderFns } from "./SearchDocumentByDocumentCrossNumbers.vue?vue&type=template&id=767c60b0&scoped=true&"
import script from "./SearchDocumentByDocumentCrossNumbers.vue?vue&type=script&lang=js&"
export * from "./SearchDocumentByDocumentCrossNumbers.vue?vue&type=script&lang=js&"
import style0 from "./SearchDocumentByDocumentCrossNumbers.vue?vue&type=style&index=0&id=767c60b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767c60b0",
  null
  
)

export default component.exports