<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Utilities" />
		</div>
		<div v-if="showTable" class="margin-top-30">
			<ve-table
				:columns="columnsList2"
				:rows="rows"
				:onRowDoubleClick="onRowDoubleClick"
				:onCellClick="onCellClick"
				:searchedOptions="false"
			>
			</ve-table>
		</div>
		<div>
			<b-tag class="is-success margin-top-30 margin-bottom-20 text-bold">
				Search Document By Document Cross Numbers
			</b-tag>
		</div>
		<section>
			<b-field label="Order Number">
				<b-input
					v-model="orderNumber"
					@focus="orderFocused"
					@keyup.native.enter="getDataByOrderNumber"
				></b-input>
				<b-button class="is-info margin-left-20" @click="getDataByOrderNumber">
					Search
				</b-button>
			</b-field>
		</section>
		<section>
			<b-field label="Dispatch Number">
				<b-input
					v-model="dispatchNumber"
					@focus="dispatchFocused"
					@keyup.native.enter="getDataByDispatchNumber"
				></b-input>
				<b-button
					class="is-info margin-left-20"
					@click="getDataByDispatchNumber"
				>
					Search
				</b-button>
			</b-field>
		</section>
		<section>
			<b-field label="Invoice Number">
				<b-input
					v-model="invoiceNumber"
					@focus="invoiceFocused"
					@keyup.native.enter="getDataByInvoiceNumber"
				></b-input>
				<b-button
					class="is-info margin-left-20"
					@click="getDataByInvoiceNumber"
				>
					Search
				</b-button>
			</b-field>
		</section>
		<section>
			<b-field label="Reminder Number">
				<b-input
					v-model="reminderNumber"
					@focus="reminderFocused"
					@keyup.native.enter="getDataByReminderNumber"
				></b-input>
				<b-button
					class="is-info margin-left-20"
					@click="getDataByReminderNumber"
				>
					Search
				</b-button>
			</b-field>
		</section>
	</layout-default-new>
</template>
<script>
import { onMounted, reactive, ref } from '@vue/composition-api'
import GetByOrderNumberQuery from '@/_srcv2/components/_shared/search-document/graphql/GetByOrderNumberQuery.graphql'
import GetByDispatchNumberQuery from '@/_srcv2/components/_shared/search-document/graphql/GetByDispatchNumberQuery.graphql'
import GetByInvoiceNumberQuery from '@/_srcv2/components/_shared/search-document/graphql/GetByInvoiceNumberQuery.graphql'
import GetByReminderNumberQuery from '@/_srcv2/components/_shared/search-document/graphql/GetByReminderNumberQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import storeSearchDocumentState from '@/_srcv2/components/_shared/search-document/useSearchDocumentState.js'
import Store from '@/store'
export default {
	name: 'SearchDocumentByDocumentCrossNumbers',
	components: {
		SubNavbar,
	},
	setup() {
		const columns = ref([])
		const rows = ref([])
		const showTable = ref(false)
		const setInitialState = () => {
			getOurInitial()
			showTable.value = false
			columns.value = columnsList.value
		}
		const initial = ref('')
		const getOurInitial = () => {
			switch (Store.getters.getUserCurrentCompany) {
				case 'alfe':
					initial.value = 'AL'
					break
				case 'totex':
					initial.value = 'TX'
					break
				case 'symbol':
					initial.value = 'SY'
					break
				case 'scandinavia':
					initial.value = 'SC'
					break
				default:
				// code block
			}
		}
		const { columnsList } = storeSearchDocumentState()
		onMounted(() => {
			setInitialState()
		})
		// --------------------------------------------------------------------------
		const orderNumber = ref('')
		const dispatchNumber = ref('')
		const invoiceNumber = ref('')
		const reminderNumber = ref('')
		// * -------------------------------------------------------------------------
		const keyMonitorOrder = (event) => {
			if (event.key === 'Enter') {
				console.log('event.key', event.key)
				console.log('keyup: Enter')
				orderFocused()
			}
		}
		const mapOrderSearchResult = (array) => {
			return array.map((item) => {
				return {
					customer: item.order_haeders.customer.customer_nickname,
					order_number: item.order_number,
					dispatch_number: item.dispatch_number,
					invoice_number: item.invoice_number,
					reminder_number:
						item.goods_transactions_reminder_lines_rel.length !== 0
							? item.goods_transactions_reminder_lines_rel[0].reminder_id
							: '',
				}
			})
		}
		const getDataByOrderNumber = () => {
			console.log('getDataByOrderNumber is fired')
			refetchOrderNumber().then((result) => {
				console.log(
					'result.data.goods_transactions',
					result.data.goods_transactions,
				)
				rows.value = mapOrderSearchResult(result.data.goods_transactions)
				showTable.value = true
			})
		}
		const orderFocused = () => {
			console.log('watch is fired', orderNumber)
			dispatchNumber.value = ''
			invoiceNumber.value = ''
			reminderNumber.value = ''
		}
		// * --------------------------------------------------------------------------
		const mapDispatchSearchResult = (array) => {
			return array.map((item) => {
				return {
					customer:
						item.goods_transactions_dispatch_haeaders_rel.customer
							.customer_nickname,
					order_number: item.order_number,
					dispatch_number: item.dispatch_number,
					invoice_number: item.invoice_number,
					reminder_number:
						item.goods_transactions_reminder_lines_rel.length !== 0
							? item.goods_transactions_reminder_lines_rel[0].reminder_id
							: '',
				}
			})
		}
		const getDataByDispatchNumber = () => {
			refetchDispatchNumber().then((result) => {
				console.log(
					'result.data.goods_transactions',
					result.data.goods_transactions,
				)
				rows.value = mapDispatchSearchResult(result.data.goods_transactions)
				showTable.value = true
			})
		}
		const dispatchFocused = () => {
			console.log('watch is fired', dispatchNumber)
			orderNumber.value = ''
			invoiceNumber.value = ''
			reminderNumber.value = ''
		}
		// * --------------------------------------------------------------------------
		const mapInvoiceSearchResult = (array) => {
			return array.map((item) => {
				return {
					customer:
						item.goods_transactions_document_transactions_rel.customer
							.customer_nickname,
					order_number: item.order_number,
					dispatch_number: item.dispatch_number,
					invoice_number: item.invoice_number,
					reminder_number:
						item.goods_transactions_reminder_lines_rel.length !== 0
							? item.goods_transactions_reminder_lines_rel[0].reminder_id
							: '',
				}
			})
		}
		const getDataByInvoiceNumber = () => {
			refetchInvoiceNumber().then((result) => {
				console.log(
					'result.data.goods_transactions',
					result.data.goods_transactions,
				)
				rows.value = mapInvoiceSearchResult(result.data.goods_transactions)
				showTable.value = true
			})
		}
		const invoiceFocused = () => {
			console.log('watch is fired', invoiceNumber)
			dispatchNumber.value = ''
			orderNumber.value = ''
			reminderNumber.value = ''
		}
		// * --------------------------------------------------------------------------
		const mapReminderSearchResult = (array) => {
			return array.map((item) => {
				return {
					customer:
						item.goods_transactions_document_transactions_rel.customer
							.customer_nickname,
					order_number: item.order_number,
					dispatch_number: item.dispatch_number,
					invoice_number: item.invoice_number,
					reminder_number:
						item.goods_transactions_reminder_lines_rel.length !== 0
							? item.goods_transactions_reminder_lines_rel[0].reminder_id
							: '',
				}
			})
		}
		const getDataByReminderNumber = () => {
			refetchReminderNumber().then((result) => {
				console.log(
					'result.data.goods_transactions',
					result.data.goods_transactions,
				)
				rows.value = mapReminderSearchResult(result.data.goods_transactions)
				showTable.value = true
			})
		}
		const reminderFocused = () => {
			console.log('watch is fired', invoiceNumber)
			dispatchNumber.value = ''
			orderNumber.value = ''
			invoiceNumber.value = ''
		}
		// * --------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'order_number') {
				console.log('params.row', params.row.order_number)
			} else if (params.column.field === 'dispatch_number') {
				console.log('params.row', params.row.dispatch_number)
			} else if (params.column.field === 'invoice_number') {
				console.log('params.row', params.row.invoice_number)
			} else if (params.column.field === 'reminder_number') {
				console.log('params.row', params.row.reminder_number)
			} else {
				console.log('params.row', params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('params.row', params.row)
		}
		// --------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		// * --------------------------------------------------------------------------
		const { refetch: refetchOrderNumber } = useQuery(
			GetByOrderNumberQuery,
			() => ({
				order_number: initial.value + ' ' + orderNumber.value,
			}),
			options,
		)
		// ! --------------------------------------------------------------------------
		const { refetch: refetchDispatchNumber } = useQuery(
			GetByDispatchNumberQuery,
			() => ({
				dispatch_number: initial.value + ' ' + dispatchNumber.value,
			}),
			options,
		)
		// ! --------------------------------------------------------------------------
		const { refetch: refetchInvoiceNumber } = useQuery(
			GetByInvoiceNumberQuery,
			() => ({
				invoice_number: initial.value + ' ' + invoiceNumber.value,
			}),
			options,
		)
		// ! --------------------------------------------------------------------------
		const { refetch: refetchReminderNumber } = useQuery(
			GetByReminderNumberQuery,
			() => ({
				reminder_id: initial.value + ' ' + reminderNumber.value,
			}),
			options,
		)
		// * --------------------------------------------------------------------------
		const columnsList2 = ref([
			{
				label: 'Customer',
				field: 'customer',
				width: '108px',
				tdClass: 'center-header',
			},
			{
				label: 'Order Number',
				field: 'order_number',
				width: '108px',
				tdClass: 'center-header',
			},
			{
				label: 'Dispatch Number',
				field: 'dispatch_number',
				width: '108px',
				tdClass: 'center-header',
			},
			{
				label: 'Invoice Number',
				field: 'invoice_number',
				width: '108px',
				tdClass: 'center-header',
			},
			{
				label: 'Reminder Number',
				field: 'reminder_number',
				width: '108px',
				tdClass: 'center-header',
			},
		])
		const tdClassFunc = (row) => {
			console.log('row', row)
			return 'header-format'
		}
		return {
			showTable,
			columns,
			rows,
			orderNumber,
			dispatchNumber,
			invoiceNumber,
			reminderNumber,
			getDataByOrderNumber,
			getDataByDispatchNumber,
			getDataByInvoiceNumber,
			getDataByReminderNumber,
			onCellClick,
			onRowDoubleClick,
			orderFocused,
			dispatchFocused,
			invoiceFocused,
			reminderFocused,
			keyMonitorOrder,
			columnsList2,
			tdClassFunc,
		}
	},
}
</script>

<style scoped>
.header-format {
	margin-left: auto;
	font-weight: bolder;
	color: #9b1c1c;
}
</style>
